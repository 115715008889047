// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { defineMessages } from 'react-intl'

import CreateFetchSelect from '@console/containers/fetch-select'

import sharedMessages from '@ttn-lw/lib/shared-messages'

import { getWebhookFormats } from '@console/store/actions/webhook-formats'

import {
  selectWebhookFormats,
  selectWebhookFormatsError,
  selectWebhookFormatsFetching,
} from '@console/store/selectors/webhook-formats'

const m = defineMessages({
  warning: 'Webhook formats unavailable',
})

export default CreateFetchSelect({
  fetchOptions: getWebhookFormats,
  optionsSelector: selectWebhookFormats,
  errorSelector: selectWebhookFormatsError,
  fetchingSelector: selectWebhookFormatsFetching,
  defaultWarning: m.warning,
  defaultTitle: sharedMessages.webhookFormat,
})
