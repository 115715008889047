// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

export const SEARCH_ACCOUNTS_BASE = 'SEARCH_ACCOUNTS'
export const [
  { request: SEARCH_ACCOUNTS, success: SEARCH_ACCOUNTS_SUCCESS, failure: SEARCH_ACCOUNTS_FAILURE },
  { request: searchAccounts, success: searchAccountsSuccess, failure: searchAccountsFailure },
] = createRequestActions(SEARCH_ACCOUNTS_BASE, (query, onlyUsers, collaboratorOf) => ({
  query,
  onlyUsers,
  collaboratorOf,
}))
