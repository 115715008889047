// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'

const useRequest = requestAction => {
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState('')
  const [result, setResult] = useState()
  const isMounted = useRef(true)

  useEffect(
    () => () => {
      isMounted.current = false
    },
    [],
  )

  useEffect(() => {
    if (requestAction) {
      const promise =
        requestAction instanceof Array
          ? Promise.all(requestAction.map(req => dispatch(attachPromise(req))))
          : typeof requestAction === 'function'
            ? requestAction(dispatch)
            : dispatch(attachPromise(requestAction))

      promise
        .then(result => {
          if (isMounted.current) {
            setResult(result)
            setFetching(false)
          }
        })
        .catch(error => {
          if (isMounted.current) {
            setError(error)
            setFetching(false)
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [fetching, error, result]
}

export default useRequest
