// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/* eslint-disable quote-props */

import { defineMessages } from 'react-intl'

import sharedMessages from '../shared-messages'

// eslint-disable-next-line capitalized-comments
// prettier-ignore
export default {
  '520': sharedMessages.unknownError,
  ...defineMessages({
    '4××': 'Client error',
    '400': 'Bad request',
    '401': 'Unauthorized',
    '403': 'Forbidden',
    '404': 'Not found',
    '409': 'Conflict',
    '429': 'Too many requests',
    '499': 'Client closed request',
    '500': 'Internal server error',
    '501': 'Not implemented',
    '503': 'Service unavailable',
    '504': 'Gateway timeout',
  })
}
